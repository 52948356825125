<template>
  <CRow>
    <CCol sm="12">
      <CRow class="preview pt-4 pt-4">
        <CCol sm="12" class="text-center pr-4">
          <button class="btn p-4 btn-success" @click="printWindow()"><CIcon name="cil-print" size="sm"/> <strong>Drucken</strong></button>
        </CCol>
        <CCol class="text-center pr-4 mt-5">
          <CButton
            color="info"
            square
            size="md"
            class="arrow-nav mr-3"
            v-on:click="subtractWeek(1)"
          >
            <CIcon name="cil-arrow-thick-left" size="3xl"/>
          </CButton>
          KW {{localWeek}} - {{year}}
          <CButton
            color="info"
            square
            size="md"
            class="arrow-nav ml-3"
            v-on:click="addWeek(1)"
          >
            <CIcon name="cil-arrow-thick-right" size="3xl"/>
          </CButton>
          <hr/>
        </CCol>
      </CRow>
      <CCard v-if="kalenderwoche" id="print-kalenderwoche">
        <CCardBody>

          <CRow>
            <CCol sm="6">
              <h1>
                Wochenübersicht
                {{user.name}}
                für die
                KW {{localWeek}} - {{year}}
              </h1>
            </CCol>
          </CRow>

          <CRow v-for="(data, tag) in kalenderwoche" v-bind:key="tag">
            <CCol sm="12">

              <hr>
              <h2>
                {{tag|moment('dddd')}}, den
                {{tag|moment('DD.MM.YYYY')}}
              </h2>

              <table class="table table-bordered">
                <tbody>

                  <tr class="active" v-if="data.protokolle.length > 0">
                    <td colspan="2">Protokolle:</td>
                  </tr>

                  <tr v-for="(protokoll, index) in data.protokolle" v-bind:key="'prt' + index">
                    <td>
                      <span v-if="protokoll.kunde">
                        {{protokoll.kunde.name1}}
                      </span>
                      <span v-if="protokoll.projekt">
                        | {{protokoll.projekt.name}}
                      </span>
                      <span class="arbeitsbeschreibung">
                        {{protokoll.arbeitsbeschreibung}}
                      </span>
                    </td>

                    <td class="text-right">
                      <span v-if="protokoll.arbeitszeiten">
                         <span v-for="(arbeitszeit, index) in protokoll.arbeitszeiten" v-bind:key="'az' + index" class="d-block">
                           <span v-if="getFormatedDate(tag) === getFormatedDate(arbeitszeit.datum) && user.userid === arbeitszeit.mitarbeiter.mitarbeiterid">
                             <span v-if="!protokoll.tageskalender" style="color: red; font-weight: bold;">
                               {{arbeitszeit.dauer|number('0.00')}}Std. <br/>
                             </span>
                             <span v-else>
                               {{arbeitszeit.dauer|number('0.00')}}Std. <br/>
                             </span>
                           </span>
                         </span>
                        <br/>
                         <strong>Gesamt {{ getSumArbeitszeiten(protokoll.arbeitszeiten, tag)|number('0.00')}} Std.</strong>
                      </span>
                    </td>
                  </tr>

                  <tr class="active" v-if="data.tageskalender.length > 0">
                    <td colspan="2">Tageskalender:</td>
                  </tr>

                  <tr v-for="(tageskalender, index) in data.tageskalender" v-bind:key="'tg' + index">
                    <td>
                      <span v-if="tageskalender.kunde">
                        {{tageskalender.kunde.name1}}
                      </span>
                      <span v-if="tageskalender.projekt">
                        | {{tageskalender.projekt.name}}
                      </span>
                      <span class="arbeitsbeschreibung">
                        {{tageskalender.taetigkeit}}
                      </span>
                    </td>
                    <td class="text-right">
                      {{tageskalender.dauer|number('0.00')}}Std.
                    </td>
                  </tr>

                  <tr class="active" v-if="data.abwesenheiten.length > 0">
                    <td colspan="2">Abwesenheiten:</td>
                  </tr>

                  <tr v-for="(abwesenheit, index) in data.abwesenheiten" v-bind:key="'aw' + index">
                    <td>
                      {{abwesenheit.name}}
                      ({{abwesenheit.art}})
                    </td>
                    <td class="text-right">
                      {{abwesenheit.dauer|number('0.00')}}Std.
                    </td>
                  </tr>

                  <tr class="active" v-if="data.freizeiten.length > 0">
                    <td colspan="2">Allg. Freizeit / Fortbildung:</td>
                  </tr>

                  <tr v-for="(freizeit, index) in data.freizeiten" v-bind:key="'fr' + index">
                    <td>
                      {{freizeit.name}}
                    </td>
                    <td class="text-right">
                      {{freizeit.dauer|number('0.00')}}Std.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-right font-weight-bold">
                      Gesamtstunden:
                      {{data.summe|number('0.00')}}
                      Std.
                    </td>
                  </tr>
                </tbody>
              </table>
            </CCol>
          </CRow>
          <hr/>
         <CRow>
           <CCol sm="12" class="text-right">
             <strong class="">Gesamtstunden in der KW: {{ getSum(kalenderwoche) }} Std.</strong>
           </CCol>
         </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'

export default {
  name: 'tageskalender-print',
  props: {
    year: String,
    week: String
  },
  data () {
    return {
      localWeek: 0,
      tag: null,
      kalenderwoche: null
    }
  },
  computed: {
    user () {
      return this.$store.getters['security/user']
    }
  },
  mounted () {
    this.localWeek = this.week
    this.loadData()
  },
  methods: {
    loadData () {
      if (this.localWeek && this.year) {
        Vue.axios.get('/tageskalender/printkw/' + this.year + '/' + this.localWeek)
          .then((response) => {
            this.kalenderwoche = response.data
          })
      }
    },
    addWeek (add) {
      if (this.localWeek && this.year && this.localWeek <= 51) {
        this.localWeek = parseInt(this.localWeek) + 1
        this.$router.push('/tageskalender/print/' + this.year + '/' + this.localWeek)
      }
      this.loadData()
      this.$forceUpdate()
    },
    subtractWeek (add) {
      if (this.localWeek && this.year && this.localWeek >= 2) {
        this.localWeek = parseInt(this.localWeek) - 1
        this.$router.push('/tageskalender/print/' + this.year + '/' + this.localWeek)
      }
      this.loadData()
      this.$forceUpdate()
    },
    printWindow: function () {
      window.print()
    },
    getFormatedDate (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    getSumArbeitszeiten (array, tag) {
      const day = tag
      return array.reduce((part, value) => {
        // console.log(value.datum)
        console.log(value.mitarbeiter)
        console.log(this.user)
        console.log(this.getFormatedDate(day))
        console.log(this.getFormatedDate(value.datum))
        if (this.user.userid === value.mitarbeiter.mitarbeiterid && this.getFormatedDate(day) === this.getFormatedDate(value.datum)) {
          return parseFloat(part) + parseFloat(value.dauer)
        }
        return parseFloat(part)
      }, 0)
    },
    getSum (data) {
      return Object.values(data).reduce((partial, value) => { return partial + value.summe }, 0)
    }
  }
}
</script>

<style lang="scss">
  @media print {
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */
  }

  @media print {
    .preview{
      display: none !important;
    }
    .header {
      display: none !important;
    }
  }
  @media only screen and (min-width: 1248px) {
    #print-protokoll{
      width: 50%;
      margin: auto;
      padding: 5px;
    }
  }

  @media only screen{
    #print-protokoll{
      margin: auto;
      padding: 20px;
    }
  }
  div#print-kalenderwoche {
    h1, h2, h3 {
      font-size: 1rem;
      font-weight: bold;
    }
    h4 {
      font-size: 1rem;
      font-style: italic;
    }
    hr {
      border-color: #DDD;
    }
    span.arbeitsbeschreibung {
      display: block;
      font-size: 0.8rem;
      margin-left: 3rem;
    }
    tbody {
     td:nth-child(2) {
        width: 20%;
     }
    }
    tr.active td {
      background-color: #f5f5f5 !important;
      -webkit-print-color-adjust: exact;
    }
  }
</style>
